import classNames from 'classnames';
import Button from '../Button';
import { routes } from '../../routes';
import { useHistory } from "react-router-dom";
import {LinkContainer} from 'react-router-bootstrap';

export default function HeroSection(props) {
  const history = useHistory();
  let className = classNames({
    'hero-section': true,
    [props.className]: props.className ? true : false
  });

  const handleGetStarted = () => {
    history.push(routes.gettingStartedNavigatingTheWebsite);
  }

  return (
    <div className={className} style={{backgroundImage: `url(${props?.image})`, backgroundPosition: "right 0px top 36px", width: "100%"}} >
      <h1 className="d3">{props.title}</h1>
      <p className="p3" dangerouslySetInnerHTML={{__html:props.description}}>
        {/* {props.description} */}
      </p>
      <div className="actions d-sm-flex justify-content-bewteen align-items-center">
        <Button type="primary" size="lg" text="Getting Started with TRP" onClick={handleGetStarted} className="mb-3 mb-sm-0"/>
        <LinkContainer to={routes.training}>
          <Button type="outline" size="lg" text="Browse Training Modules  ›"  className="mb-3 mb-sm-auto" />
        </LinkContainer>

       
        
      </div>
    </div>
  );
}