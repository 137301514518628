import classNames from 'classnames';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useHistory
} from "react-router-dom";
import Button from '../Button';
import Icon from '../../components/Icon';
import UNFPALogo from '../../assets/images/partner-UNFPA.svg';
import USAIDLogo from '../../assets/images/partner-USAID.svg';
import WHOLogo from '../../assets/images/partner-WHO.svg';


export default function HeroSection(props) {

  return (
    <>
      <div className="d-none d-md-block col-7 partners" style={{paddingLeft: '0px'}}>
        <a href="https://usaidmomentum.org/"><Icon icon={USAIDLogo} width={160} height={48}/></a>
        <a href="http://www.who.int/en/"><Icon icon={WHOLogo} width={157} height={48}/></a>
        <a href="http://www.unfpa.org/"><Icon icon={UNFPALogo} width={107} height={48}/></a>
      </div>
      <div className="d-flex justify-content-between align-items-center   d-block d-md-none col partners">
        <a href="https://usaidmomentum.org/"><Icon className="my-2 my-sm-auto" icon={USAIDLogo} width={94} height={28}/></a>
        <a href="http://www.who.int/en/"><Icon className="my-2 my-sm-auto"  icon={WHOLogo} width={91} height={28}/></a>
        <a href="http://www.unfpa.org/"><Icon className="my-2 my-sm-auto"  icon={UNFPALogo} width={63} height={28}/></a>
      </div>
    </>
  );
}